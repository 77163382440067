import type {BaseItemObject} from '@PosterWhiteboard/items/item/item.types';
import type {VectorItemObject} from '@PosterWhiteboard/items/vector-item/vector-item.types';
import type {TextStylesObject, TextVerticalAlignType} from '@PosterWhiteboard/classes/text-styles.class';
import type {RGB} from '@Utils/color.util';
import type {TextListObject} from '@PosterWhiteboard/items/text-item/text-list';

export interface TextItemObject extends BaseItemObject {
  baseWidth: number;
  backgroundType: number;
  backgroundColor: RGB;
  backgroundColorAlpha: number;
  fontFamily: string;
  wrappedLines: Array<string>;
  verticalAlign: TextVerticalAlignType;
  verticalPadding: number;
  background?: VectorItemObject;
  list: TextListObject;
  textStyles: TextStylesObject;
  text: string;
}

export const FONT_SIZE_MIN = 1;
export const FONT_SIZE_MAX = 999;
