import type {ReactElement} from 'react';
import React, {useEffect, useRef, useState} from 'react';
import {ControlledList} from '@Components/controlled-list';
import type {ControlledListItemType} from '@Components/controlled-list/controlled-list.types';
import {CONTROLLED_LIST_ITEM_TYPE} from '@Components/controlled-list/controlled-list.types';
import {updatePopUpStateAndPosition} from '@Components/poster-editor/components/poster-popup-menu/poster-popup-menu-slice';
import {ColorTypes} from '@Components/controlled-list/components/controlled-list-item-type-7';
import {makeShortcut} from '@Utils/shortcut.util';
import {onOpenResizeModal} from '@Components/poster-editor/library/poster-editor-open-modals';
import {useSingleActivePosterItem} from '@Hooks/poster-editor/useSingleActivePosterItem';
import {GroupHorizontalAlignment, GroupVeticalAlignment} from '@PosterWhiteboard/page/alignment.class';
import type * as Fabric from '@postermywall/fabricjs-2';
import {getIsSpellCheckEnabledFromStore, updateIsSpellCheckEnabled} from '@Libraries/spell-check-library';
import {useGetSelectedSlide} from '@Hooks/poster-editor/useGetSelectedSlide';
import {isUserPremium} from '@Libraries/user.library';
import {PosterEvent} from '@PosterWhiteboard/poster/poster.types';
import type {FabricPositionalParams} from '@Utils/fabric.util';
import {copyToClipboard} from '@Utils/clipboard.util';
import {Canvas, Textbox} from '@postermywall/fabricjs-2';
import styles from './poster-popup-menu.module.scss';
import {useAppSelector} from '@/hooks';
import useOutsideClick from '@/hooks/useOutsideClick';

export function PosterPopupMenu(): ReactElement | null {
  const popupState = useAppSelector((state) => {
    return state.posterPopUpMenu;
  });
  const [shouldInitFabricTouchEvent, setShouldInitFabricTouchEvent] = useState(false);
  const popUpRef = useRef<HTMLDivElement>(null);
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  const activeItem = useSingleActivePosterItem();
  const itemsOnPage = currentPage?.items.getNumberOfItemsOnPage();
  const activeSlide = useGetSelectedSlide();
  const isSpellCheckEnabled = getIsSpellCheckEnabledFromStore();
  const activeCanvas = currentPage?.fabricCanvas;

  useEffect(() => {
    if (popUpRef.current) {
      setPositionForPopup();
    }
    if (!shouldInitFabricTouchEvent && window.posterEditor?.whiteboard) {
      setShouldInitFabricTouchEvent(true);
    }
  });

  useEffect(() => {
    if (shouldInitFabricTouchEvent) {
      window.posterEditor?.whiteboard?.on(PosterEvent.FABRIC_CANVAS_TOUCH_START_DETECTED, closePopUp);

      return () => {
        window.posterEditor?.whiteboard?.off(PosterEvent.FABRIC_CANVAS_TOUCH_START_DETECTED);
      };
    }
    return () => {};
  }, [shouldInitFabricTouchEvent]);

  const onOutsideClick = (): void => {
    closePopUp();
  };

  const closePopUp = (): void => {
    window.PMW.redux.store.dispatch(
      updatePopUpStateAndPosition({
        openPosterPopUpMenu: false,
        popUpPosition: popupState.popUpPosition,
        target: undefined,
        eventCanvasOffset: popupState.eventCanvasOffset,
      })
    );
  };

  useOutsideClick(popUpRef, onOutsideClick);

  if (!(activeCanvas instanceof Canvas)) {
    return null;
  }

  const setPositionForPopup = (): void => {
    const windowHeight = $(window).height();
    const windowWidth = $(window).width();
    const boundingRect = popUpRef.current?.getBoundingClientRect();

    const initialPosX = boundingRect?.left;
    const initialPosY = boundingRect?.top;
    let posX = initialPosX;
    let posY = initialPosY;

    if (popUpRef.current && boundingRect && windowWidth && windowHeight) {
      const navBarHeight = 60;
      const seekbarTop = 120;
      const popUpMenuTotalVerticalMargin = 30;
      const spaceBetweenNavBarAndSeekbar = windowHeight - navBarHeight - seekbarTop;
      const maxHeight = spaceBetweenNavBarAndSeekbar - popUpMenuTotalVerticalMargin;
      // if the window width is lower than minWindowWidth, we show the pop up menu in the middle of screen
      const minWindowWidth = 550;

      popUpRef.current.style.maxHeight = `${maxHeight.toString()}px`;

      if (windowWidth > minWindowWidth) {
        if (boundingRect.bottom > windowHeight) {
          posY = windowHeight - boundingRect.height;
        }
        if (boundingRect.right > windowWidth) {
          posX = windowWidth - boundingRect.width;
        }

        if (posX && posY && (initialPosX !== posX || initialPosY !== posY)) {
          popUpRef.current.style.top = `${posY.toString()}px`;
          popUpRef.current.style.left = `${posX.toString()}px`;
        }
      } else {
        // TODO: See if this can be replaced by popUpRef.current.style.top = `${(windowHeight / 2).toString()}px`; in phase 2.
        popUpRef.current.style.top = `${(spaceBetweenNavBarAndSeekbar / 2 + navBarHeight).toString()}px`;
        popUpRef.current.style.left = `50%`;
        popUpRef.current.style.transform = 'translate(-50%, -50%)';
      }
    }
  };

  const getCanvasPopUpList = (): ReactElement => {
    return <ControlledList id="canvas-pop-up" items={getPopUpItems()} />;
  };

  const getPopUpItems = (): Array<ControlledListItemType> => {
    if (popupState.target) {
      if (!activeCanvas) {
        return [];
      }
      const activeObjects = activeCanvas?.getActiveObjects();

      if (activeObjects && activeObjects.length > 1 && activeCanvas?.getActiveObject() === popupState.target) {
        return getPopUpItemsForGroup();
      }

      return getPopUpItemsForItem();
    }

    return getPopUpItemsForCanvas();
  };

  const getPopUpItemsForItem = (): Array<ControlledListItemType> => {
    const activeObject = currentPage?.activeSelection.getActiveObject();

    if (activeObject instanceof Textbox && activeObject.isEditing) {
      return getPopUpForTextEditMode(activeObject);
    }
    return getPopUpItemsForSingleItem();
  };

  const getPopUpForTextEditMode = (fabricTextbox: Fabric.Textbox): Array<ControlledListItemType> => {
    const textHasNoSelection = fabricTextbox.selectionStart === fabricTextbox.selectionEnd;
    return [
      {
        id: '1',
        icon: 'icon-cut',
        text: window.i18next.t('pmwjs_cut'),
        shortcut: makeShortcut(['Ctrl', 'X']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        isDisabled: textHasNoSelection,
        onClick: (): void => {
          void onCutFromText();
          closePopUp();
        },
      },
      {
        id: '2',
        icon: 'icon-copy',
        text: window.i18next.t('pmwjs_copy_text'),
        shortcut: makeShortcut(['Ctrl', 'C']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        isDisabled: textHasNoSelection,
        onClick: (): void => {
          void onCopyFromText();
          closePopUp();
        },
      },
      {
        id: '3',
        icon: 'icon-poster-print',
        text: window.i18next.t('pmwjs_paste'),
        shortcut: makeShortcut(['Ctrl', 'V']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (id, e): void => {
          void onPasteText(e as unknown as Event);
          closePopUp();
        },
      },
      {
        id: '4',
        icon: 'icon-select-all',
        text: window.i18next.t('pmwjs_select_all'),
        shortcut: makeShortcut(['Ctrl', 'A']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (): void => {
          onSelectAllText();
          closePopUp();
        },
      },
      {
        id: '5',
        type: CONTROLLED_LIST_ITEM_TYPE.DIVIDER,
      },
      {
        id: '6',
        icon: isSpellCheckEnabled ? 'icon-spellcheck-off' : 'icon-spellcheck',
        text: isSpellCheckEnabled ? window.i18next.t('pmwjs_disable_spellcheck') : window.i18next.t('pmwjs_enable_spellcheck'),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (): void => {
          if (isUserPremium()) {
            void updateIsSpellCheckEnabled(!isSpellCheckEnabled);
          } else {
            window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_SPELL_CHECK);
          }
          closePopUp();
        },
      },
    ];
  };

  const getPopUpItemsForGroup = (): Array<ControlledListItemType> => {
    return [
      {
        id: '1',
        icon: 'icon-duplicate',
        text: window.i18next.t('pmwjs_duplicate'),
        shortcut: makeShortcut(['Ctrl', 'D']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (): void => {
          currentPage?.activeSelection?.onDuplicate();
          closePopUp();
        },
      },
      {
        id: '2',
        icon: 'icon-copy',
        text: window.i18next.t('pmwjs_copy_group'),
        shortcut: makeShortcut(['Ctrl', 'C']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (id, e): void => {
          onCopySelection(e);
          closePopUp();
        },
      },
      {
        id: '3',
        icon: 'icon-delete',
        color: ColorTypes.danger,
        text: window.i18next.t('pmwjs_delete'),
        shortcut: makeShortcut(['Del']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (): void => {
          currentPage?.activeSelection?.deleteSelectedItems();
          closePopUp();
        },
      },
      {
        id: '4',
        type: CONTROLLED_LIST_ITEM_TYPE.DIVIDER,
      },
      {
        id: '5',
        icon: 'icon-left-align-shapes',
        text: window.i18next.t('pmwjs_left_align'),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (): void => {
          onHorizontalAlignmentModified(GroupHorizontalAlignment.LEFT);
          closePopUp();
        },
      },
      {
        id: '6',
        icon: 'icon-center-align-shapes',
        text: window.i18next.t('pmwjs_center_horizontally'),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (): void => {
          onHorizontalAlignmentModified(GroupHorizontalAlignment.CENTER);
          closePopUp();
        },
      },
      {
        id: '7',
        icon: 'icon-right-align-shapes',
        text: window.i18next.t('pmwjs_right_align'),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (): void => {
          onHorizontalAlignmentModified(GroupHorizontalAlignment.RIGHT);
          closePopUp();
        },
      },
      {
        id: '8',
        icon: 'icon-top-align-shapes',
        text: window.i18next.t('pmwjs_top_align'),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (): void => {
          onVerticalAlignmentModified(GroupVeticalAlignment.TOP);
          closePopUp();
        },
      },
      {
        id: '9',
        icon: 'icon-middle-align-shapes',
        text: window.i18next.t('pmwjs_center_vertically'),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (): void => {
          onVerticalAlignmentModified(GroupVeticalAlignment.CENTER);
          closePopUp();
        },
      },
      {
        id: '10',
        icon: 'icon-bottom-align-shapes',
        text: window.i18next.t('pmwjs_bottom_align'),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (): void => {
          onVerticalAlignmentModified(GroupVeticalAlignment.BOTTOM);
          closePopUp();
        },
      },
    ];
  };

  const getPopUpItemsForCanvas = (): Array<ControlledListItemType> => {
    const popUpItems: Array<ControlledListItemType> = [
      {
        id: '1',
        icon: 'icon-poster-print',
        text: window.i18next.t('pmwjs_paste'),
        shortcut: makeShortcut(['Ctrl', 'V']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (id, e): void => {
          void pasteGraphicItems(e);
          closePopUp();
        },
      },
      {
        id: '2',
        icon: 'icon-resize',
        text: window.i18next.t('pmwjs_resize'),
        shortcut: makeShortcut(['Shift', 'R']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (): void => {
          onOpenResizeModal();
          closePopUp();
        },
      },
      {
        id: '4',
        icon: 'icon-save',
        text: window.i18next.t('pmwjs_save'),
        shortcut: makeShortcut(['Ctrl', 'S']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (): void => {
          void window.posterEditor?.whiteboard?.savePoster.save();
          closePopUp();
        },
      },
    ];
    if (currentPage?.items.hasLockedItems()) {
      popUpItems.splice(2, 0, {
        id: '3',
        icon: 'icon-unlock ',
        text: window.i18next.t('pmwjs_unlock_all_items'),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (): void => {
          currentPage?.items.unLockAllLockedItems();
          closePopUp();
        },
      });
    }
    return popUpItems;
  };

  const getPopUpItemsForSingleItem = (): Array<ControlledListItemType> => {
    const items: Array<ControlledListItemType> = [
      {
        id: '1',
        icon: 'icon-send-to-back',
        text: window.i18next.t('pmwjs_send_backward'),
        shortcut: makeShortcut(['Shift', '↓']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        isDisabled: !itemsOnPage || itemsOnPage <= 1 || activeItem?.isOnBottom(),
        onClick: (): void => {
          currentPage?.items.sendBackwardsSelectedItem();
          closePopUp();
        },
      },
      {
        id: '2',
        icon: 'icon-to-back',
        text: window.i18next.t('pmwjs_send_to_bottom'),
        shortcut: makeShortcut(['Ctrl', 'Shift', '↓']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        isDisabled: activeItem?.isOnBottom() || itemsOnPage === 1,
        onClick: (): void => {
          currentPage?.items.sendToBackSelectedItem();
          closePopUp();
        },
      },
      {
        id: '3',
        icon: 'icon-send-to-front',
        text: window.i18next.t('pmwjs_bring_forward'),
        shortcut: makeShortcut(['Shift', '↑']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        isDisabled: !itemsOnPage || itemsOnPage <= 1 || activeItem?.isOnTop(),
        onClick: (): void => {
          currentPage?.items.bringForwardSelectedItem();
          closePopUp();
        },
      },
      {
        id: '4',
        icon: 'icon-to-front',
        text: window.i18next.t('pmwjs_bring_to_top'),
        shortcut: makeShortcut(['Ctrl', 'Shift', '↑']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        isDisabled: activeItem?.isOnTop(),
        onClick: (): void => {
          currentPage?.items.bringToFrontSelectedItem();
          closePopUp();
        },
      },
      {
        id: '5',
        type: CONTROLLED_LIST_ITEM_TYPE.DIVIDER,
      },
      {
        id: '6',
        icon: 'icon-duplicate',
        text: window.i18next.t('pmwjs_duplicate'),
        shortcut: makeShortcut(['Ctrl', 'D']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (): void => {
          currentPage?.activeSelection?.onDuplicate();
          closePopUp();
        },
      },
      {
        id: '7',
        icon: 'icon-copy',
        text: window.i18next.t('pmwjs_copy_item'),
        shortcut: makeShortcut(['Ctrl', 'C']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (id, e): void => {
          onCopySelection(e);
          closePopUp();
        },
      },
      {
        id: '8',
        icon: 'icon-poster-print',
        text: window.i18next.t('pmwjs_paste'),
        shortcut: makeShortcut(['Ctrl', 'V']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (id, e): void => {
          void pasteGraphicItems(e);
          closePopUp();
        },
      },
      {
        id: '9',
        color: ColorTypes.danger,
        icon: 'icon-delete',
        text: window.i18next.t('pmwjs_delete'),
        shortcut: makeShortcut(['Del']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (): void => {
          currentPage?.activeSelection?.deleteSelectedItems();
          closePopUp();
        },
      },
      {
        id: '10',
        icon: activeItem?.lockMovement ? 'icon-unlock' : 'icon-https',
        text: activeItem?.lockMovement ? window.i18next.t('pmwjs_unlock_item') : window.i18next.t('pmwjs_lock_item'),
        shortcut: makeShortcut(['Ctrl', 'L']),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        onClick: (): void => {
          void activeItem?.updateFromObject({
            lockMovement: !activeItem?.lockMovement,
          });
          closePopUp();
        },
      },
    ];

    if (activeItem?.isText() || activeSlide?.isTextSlide()) {
      items.push({
        id: '11',
        icon: isSpellCheckEnabled ? 'icon-spellcheck-off' : 'icon-spellcheck',
        text: isSpellCheckEnabled ? window.i18next.t('pmwjs_disable_spellcheck') : window.i18next.t('pmwjs_enable_spellcheck'),
        type: CONTROLLED_LIST_ITEM_TYPE.DEFAULT_7,
        isSelected: false,
        rightIcon: isUserPremium() ? '' : 'icon-crown',
        rightIconClassName: 'color-premium',
        onClick: (): void => {
          if (isUserPremium()) {
            void updateIsSpellCheckEnabled(!isSpellCheckEnabled);
          } else {
            window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_SPELL_CHECK);
          }
          closePopUp();
        },
      });
    }

    return items;
  };

  const getMenuPosition = (): FabricPositionalParams => {
    return {
      left: popupState.popUpPosition?.left,
      top: popupState.popUpPosition?.top,
    };
  };

  const onHorizontalAlignmentModified = (alignmentType: GroupHorizontalAlignment): void => {
    currentPage?.activeSelection?.alignment?.horizontalAlignment(alignmentType);
  };

  const onVerticalAlignmentModified = (alignmentType: GroupVeticalAlignment): void => {
    currentPage?.activeSelection?.alignment?.verticalAlignment(alignmentType);
  };

  const onCutFromText = async (): Promise<void> => {
    const activeObject = activeCanvas?.getActiveObject();

    if ((activeItem?.isText() || activeItem?.isSlideshow()) && activeCanvas && activeObject && activeObject instanceof Textbox) {
      await copyToClipboard(activeObject.text.slice(activeObject.selectionStart, activeObject.selectionEnd));
      const prevText = activeObject.text;
      const updatedText = prevText.substring(0, activeObject.selectionStart) + prevText.substring(activeObject.selectionEnd, prevText.length);
      activeItem.updateActiveText(updatedText);
      activeObject.selectionEnd = activeObject.selectionStart;
    }
  };

  const onCopyFromText = async (): Promise<void> => {
    const activeObject = activeCanvas?.getActiveObject();

    if (activeCanvas && activeObject && activeObject instanceof Textbox) {
      const selectionStart = activeObject.hiddenTextarea ? activeObject.hiddenTextarea.selectionStart : activeObject.selectionStart;
      const selectionEnd = activeObject.hiddenTextarea ? activeObject.hiddenTextarea.selectionEnd : activeObject.selectionEnd;
      await copyToClipboard(activeObject.text.slice(selectionStart, selectionEnd));
      activeObject.selectionStart = selectionEnd;
      activeObject.selectionEnd = selectionEnd;
    }
  };

  const onPasteText = async (e?: Event): Promise<void> => {
    const activeObject = activeCanvas?.getActiveObject();

    if ((activeItem?.isText() || activeItem?.isSlideshow()) && activeCanvas && activeObject && activeObject instanceof Textbox) {
      await activeItem.onPasteText(e);
    }
  };

  const onSelectAllText = (): void => {
    const activeObject = activeCanvas?.getActiveObject();
    if (activeObject && activeObject instanceof Textbox) {
      activeObject.selectionStart = 0;
      activeObject.selectionEnd = activeObject.text.length;
      activeCanvas?.requestRenderAll();
    }
  };

  const onCopySelection = (e?: React.MouseEvent<HTMLElement>): void => {
    if (currentPage) {
      currentPage.clipboard.copySelection(e);
    }
  };

  const pasteGraphicItems = async (e?: React.MouseEvent<HTMLElement>): Promise<void> => {
    if (currentPage) {
      await currentPage.clipboard.pasteItems(e, {
        isPasteFromRightClick: true,
        eventCanvasOffset: {left: popupState.eventCanvasOffset?.left, top: popupState.eventCanvasOffset?.top},
      });
    }
  };

  return popupState.openPosterPopUpMenu ? (
    <div ref={popUpRef} className={`spacing-p-2 ${styles.canvasPopupMenu} `} style={getMenuPosition()}>
      {getCanvasPopUpList()}
    </div>
  ) : null;
}
