import type * as Fabric from '@postermywall/fabricjs-2';
import type {CanvasEvents} from '@postermywall/fabricjs-2';
import {Point} from '@postermywall/fabricjs-2';

interface TextToRender {
  text: string;
  textWidth: number;
  coords?: Fabric.Point;
}

/**
 * Offet of transform details from the canvas object
 * @type {number}
 */
const TRANSFORM_DETAIL_OFFSET = 30;

export const initTransformDetail = (canvas: Fabric.Canvas): void => {
  const textToRender: TextToRender = {
    text: '',
    textWidth: 0,
    coords: undefined,
  };
  const ctx = canvas.getSelectionContext();

  canvas.on('object:scaling', renderTransformDetail);
  canvas.on('object:rotating', renderTransformDetail);
  canvas.on('object:moving', renderTransformDetail);

  function renderTransformDetail(e: CanvasEvents['object:scaling']): void {
    if (!e.transform || !e.target) {
      return;
    }

    const fabricObject = e.target;
    const text = e.transform.action ? getTextForTransform(fabricObject, e.transform.action) : '';

    ctx.font = '10px Arial';
    textToRender.textWidth = ctx.measureText(text).width;
    textToRender.text = text;
    textToRender.coords = getCoordinatesToRenderTransformDetail(fabricObject, textToRender.textWidth, canvas, e.transform.corner);
  }

  canvas.on('before:render', (): void => {
    if (canvas.contextTop) {
      canvas.contextTopDirty = true;
    }
  });

  canvas.on('after:render', (): void => {
    if (textToRender.text && textToRender.coords) {
      ctx.font = '10px Arial';
      const padding = 1.5;
      const textHeight = 8;

      ctx.save();
      ctx.fillStyle = '#ffffff';
      ctx.globalAlpha = 0.3;
      ctx.fillRect(textToRender.coords.x - padding, textToRender.coords.y - textHeight - padding, textToRender.textWidth + 2 * padding, textHeight + 2 * padding + 1);
      ctx.restore();

      // draw text inside the rectangle
      ctx.save();
      ctx.fillStyle = '#000000';
      ctx.fillText(textToRender.text, Math.round(textToRender.coords.x), Math.round(textToRender.coords.y));
      ctx.restore();
    }
  });

  canvas.on('mouse:up', (): void => {
    textToRender.text = '';
    canvas.renderAll();
  });
};

const getTextForTransform = (fabricObject: Fabric.Object, action: string): string => {
  let text = '';

  switch (action) {
    case 'drag': {
      let x;
      let y;
      const {angle} = fabricObject;
      const corners = fabricObject.getCornerPoints(fabricObject.getCenterPoint());

      if (angle > 45 && angle < 135) {
        x = corners.bl.x;
        y = corners.bl.y;
      } else if (angle > 135 && angle < 225) {
        x = corners.br.x;
        y = corners.br.y;
      } else if (angle > 225 && angle < 315) {
        x = corners.tr.x;
        y = corners.tr.y;
      } else {
        x = corners.tl.x;
        y = corners.tl.y;
      }
      text = `${Math.round(x)}, ${Math.round(y)}`;
      break;
    }
    case 'rotate':
      text = `${Math.round(fabricObject.angle)}°`;
      break;
    case 'scaleX':
    case 'scaleY':
    case 'scale':
      text = `${Math.round(fabricObject.width * fabricObject.scaleX)} x ${Math.round(fabricObject.height * fabricObject.scaleY)}`;
      break;

    default:
      text = '';
  }
  return text;
};

const getCoordinatesToRenderTransformDetail = (fabricObject: Fabric.Object, textWidth: number, canvas: Fabric.Canvas, cornerName: string | 0): Fabric.Point => {
  const objectCoords = fabricObject.oCoords;
  let dx;
  let dy;
  const {angle} = fabricObject;
  const theta = angle * (Math.PI / 180);
  // these offset values will be used for centering the text
  const textWidthOffset = textWidth / 2;
  const textHeight = 8;
  const textHeightOffset = textHeight / 2;

  switch (cornerName) {
    case 'tl':
      dx = objectCoords.tl.x + TRANSFORM_DETAIL_OFFSET * Math.sin(theta - Math.PI / 4);
      dy = objectCoords.tl.y - TRANSFORM_DETAIL_OFFSET * Math.cos(theta - Math.PI / 4);

      if ((angle >= 0 && angle < 45) || angle > 225) {
        dx -= textWidth;
      }
      break;

    case 'tr':
      dx = objectCoords.tr.x + TRANSFORM_DETAIL_OFFSET * Math.sin(theta + Math.PI / 4);
      dy = objectCoords.tr.y - TRANSFORM_DETAIL_OFFSET * Math.cos(theta + Math.PI / 4);

      if (angle > 135 && angle < 315) {
        dx -= textWidth;
      }
      break;

    case 'bl':
      dx = objectCoords.bl.x - TRANSFORM_DETAIL_OFFSET * Math.sin(theta + Math.PI / 4);
      dy = objectCoords.bl.y + TRANSFORM_DETAIL_OFFSET * Math.cos(theta + Math.PI / 4);

      if ((angle >= 0 && angle < 135) || angle > 315) {
        dx -= textWidth;
      }
      break;

    case 'br':
      dx = objectCoords.br.x + TRANSFORM_DETAIL_OFFSET * Math.cos(theta + Math.PI / 4);
      dy = objectCoords.br.y + TRANSFORM_DETAIL_OFFSET * Math.sin(theta + Math.PI / 4);

      if (angle > 45 && angle < 225) {
        dx -= textWidth;
      }
      break;

    case 'mt':
      dx = objectCoords.mtr.x - textWidthOffset + (TRANSFORM_DETAIL_OFFSET + textWidthOffset) * Math.sin(theta);
      dy = objectCoords.mtr.y + textHeightOffset - TRANSFORM_DETAIL_OFFSET * Math.cos(theta);
      break;

    case 'mr':
      dx = objectCoords.mr.x - textWidthOffset + (TRANSFORM_DETAIL_OFFSET + textWidthOffset) * Math.cos(theta);
      dy = objectCoords.mr.y + textHeightOffset + TRANSFORM_DETAIL_OFFSET * Math.sin(theta);
      break;

    case 'mb':
      dx = objectCoords.mb.x - textWidthOffset - (TRANSFORM_DETAIL_OFFSET + textWidthOffset) * Math.sin(theta);
      dy = objectCoords.mb.y + textHeightOffset + TRANSFORM_DETAIL_OFFSET * Math.cos(theta);
      break;

    case 'ml':
      dx = objectCoords.ml.x - textWidthOffset - (TRANSFORM_DETAIL_OFFSET + textWidthOffset) * Math.cos(theta);
      dy = objectCoords.ml.y + textHeightOffset - TRANSFORM_DETAIL_OFFSET * Math.sin(theta);
      break;

    case 'mtr':
      dx = objectCoords.mtr.x - textWidthOffset + (TRANSFORM_DETAIL_OFFSET + textWidthOffset) * Math.sin(theta);
      dy = objectCoords.mtr.y + textHeightOffset - TRANSFORM_DETAIL_OFFSET * Math.cos(theta);
      break;

    default:
      if (angle > 45 && angle < 135) {
        dx = objectCoords.bl.x - TRANSFORM_DETAIL_OFFSET * Math.sin(theta + Math.PI / 4) - textWidth;
        dy = objectCoords.bl.y + TRANSFORM_DETAIL_OFFSET * Math.cos(theta + Math.PI / 4);
      } else if (angle > 135 && angle < 225) {
        dx = objectCoords.br.x + TRANSFORM_DETAIL_OFFSET * Math.cos(theta + Math.PI / 4) - textWidth;
        dy = objectCoords.br.y + TRANSFORM_DETAIL_OFFSET * Math.sin(theta + Math.PI / 4);
      } else if (angle > 225 && angle < 315) {
        dx = objectCoords.tr.x + TRANSFORM_DETAIL_OFFSET * Math.sin(theta + Math.PI / 4) - textWidth;
        dy = objectCoords.tr.y - TRANSFORM_DETAIL_OFFSET * Math.cos(theta + Math.PI / 4);
      } else {
        dx = objectCoords.tl.x + TRANSFORM_DETAIL_OFFSET * Math.sin(theta - Math.PI / 4) - textWidth;
        dy = objectCoords.tl.y - TRANSFORM_DETAIL_OFFSET * Math.cos(theta - Math.PI / 4);
      }
  }

  // ensure that dx and dy are within the canvas range
  const cw = canvas.width;
  const ch = canvas.height;
  const padding = 3;

  if (dx < 0) {
    dx -= dx - padding;
  } else if (dx + textWidth > cw) {
    dx -= dx - cw + textWidth + padding;
  }

  if (dy - textHeight < 0) {
    dy -= dy - textHeight - padding;
  } else if (dy > ch) {
    dy -= dy - ch + padding;
  }

  return new Point(dx, dy);
};
