import {isWebpSupported} from '@Utils/browser.util';
import {doesPublicFileExistAsync} from '@Utils/file.util';
import {getImageItemBigScreenSizeRemovedBackgroundUrl} from '@Libraries/image-item.library';

interface RemoveBackgroundResponse {
  remainingRemoveBackgroundCredits: number;
}

const REMOVED_BG_IMAGE_COST = 1;

export const removeImageBackground = async (imageHashedFilename: string): Promise<RemoveBackgroundResponse> => {
  return (await window.PMW.writeLocal('posterimage/removeUserImageBackground', {
    userImageHashedFilename: imageHashedFilename,
  })) as Promise<RemoveBackgroundResponse>;
};

export const getRemoveBackgroundCost = async (hashedFilename: string, imageSource: string): Promise<number> => {
  const destURLScreen = getImageItemBigScreenSizeRemovedBackgroundUrl(hashedFilename, imageSource);
  if (await doesPublicFileExistAsync(destURLScreen)) {
    return 0;
  }
  return REMOVED_BG_IMAGE_COST;
};

/**
 * Returns whether the filetype is of an image
 * @param {string} fileType
 * @returns {boolean}
 */
export const isFiletypeImage = (fileType: string) => {
  if (fileType === '') {
    return false;
  }
  return fileType.indexOf('image/') !== -1;
};

/**
 * Returns the browser compatible file extension
 * @param {string} fileExtension
 * @return {string}
 */
export const getCompatibleImageFileExtension = (fileExtension: string): string => {
  const FALLBACK_IMAGE_EXTENSION_FOR_WEBP = 'png';
  if (fileExtension === 'webp' && !isWebpSupported()) {
    return FALLBACK_IMAGE_EXTENSION_FOR_WEBP;
  }
  return fileExtension;
};

/**
 * @param {string} src
 * @returns {mise<number[]>Pro}
 */
export const getImageDimensions = async (src: string): Promise<[number, number]> => {
  const img = await loadImageAsync(src);
  return [img.naturalWidth, img.naturalHeight];
};

export const removeAlmostTranparentPixels = async (img: HTMLImageElement): Promise<HTMLImageElement> => {
  const ALPHA_THRESHOLD = 3;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error(`Failed to get context of canvas`);
  }

  canvas.width = img.width;
  canvas.height = img.height;
  ctx.drawImage(img, 0, 0);

  const imgd = ctx.getImageData(0, 0, img.width, img.height);
  const pix = imgd.data;

  for (let i = 0, n = pix.length; i < n; i += 4) {
    if (pix[i + 3] < ALPHA_THRESHOLD) {
      pix[i + 3] = 0;
    }
  }

  ctx.putImageData(imgd, 0, 0);
  const url = canvas.toDataURL();
  return loadImageAsync(url);
};

export const hasTransparency = (img: HTMLImageElement): boolean => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error(`Failed to get context of canvas`);
  }
  canvas.width = img.width;
  canvas.height = img.height;
  ctx.drawImage(img, 0, 0);

  const {data} = ctx.getImageData(0, 0, canvas.width, canvas.height);
  let hasAlphaPixels = false;
  for (let i = 3, n = data.length; i < n; i += 4) {
    if (data[i] < 255) {
      hasAlphaPixels = true;
      break;
    }
  }
  return hasAlphaPixels;
};

/**
 * @param {string} src
 * @returns {Promise<number>}
 */
export const getImageRatio = async (src: string): Promise<number> => {
  const [width, height] = await getImageDimensions(src);
  return width / height;
};

/**
 * Loads image with a promise
 * @param src
 * @return {Promise<HTMLImageElement>}
 */
export const loadImageAsync = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = (): void => {
      resolve(img);
    };
    img.crossOrigin = 'anonymous';
    img.onerror = reject;
    img.src = src;
  });
};

export const loadImageSync = (url: string, successCB: (img: any) => any, failCB: () => any, setCrossOrigin = true): void => {
  const img = new Image();
  img.onload = successCB.bind(this, img);
  if (setCrossOrigin) {
    img.crossOrigin = 'anonymous';
  }
  img.onerror = failCB;
  img.onabort = failCB;
  img.src = url;
};

export const dataURLToArray = (imageDataURL: string): Promise<Uint8ClampedArray> => {
  return new Promise((resolve, reject) => {
    const imageElem = document.createElement('img');
    imageElem.src = imageDataURL;

    imageElem.onload = (): void => {
      const w = imageElem.width;
      const h = imageElem.height;

      const canvas = document.createElement('canvas');
      canvas.width = imageElem.width;
      canvas.height = imageElem.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error(`Failed to get context of canvas`));
        return;
      }
      ctx.drawImage(imageElem, 0, 0);

      resolve(ctx.getImageData(0, 0, w, h).data);
    };
  });
};
