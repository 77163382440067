import {Item} from '@PosterWhiteboard/items/item/item.class';
import QRCode from 'qrcode';
import type {RGB} from '@Utils/color.util';
import {rgbToHexString} from '@Utils/color.util';
import {ElementDataType} from '@Libraries/add-media-library';
import {openPosterEditorQRContentModal} from '@Modals/poster-editor-qr-content-modal';
import type {FabricObject, Group} from '@postermywall/fabricjs-2';
import {loadSVGFromString, util} from '@postermywall/fabricjs-2';
import type {BaseItemObject} from './item/item.types';
import {ITEM_TYPE} from './item/item.types';

export interface QRCodeItemObject extends BaseItemObject {
  message: string;
  qrForegroundColor: RGB;
  qrBackgroundColor: RGB;
  isBackgroundTransparent: boolean;
}

export const DEFAULT_QR_MESSAGE = 'https://www.postermywall.com';
export const DEFAULT_QR_MAX_LENGTH = 1400;

export class QRCodeItem extends Item {
  public message = DEFAULT_QR_MESSAGE;
  public qrForegroundColor: RGB = [0, 0, 0];
  public qrBackgroundColor: RGB = [255, 255, 255];
  public isBackgroundTransparent = true;
  public gitype: ITEM_TYPE.QR_CODE = ITEM_TYPE.QR_CODE;

  protected itemObjectHasDestructiveChanges = (oldItemObject: QRCodeItemObject): boolean => {
    return (
      oldItemObject.message !== this.message ||
      oldItemObject.qrForegroundColor !== this.qrForegroundColor ||
      oldItemObject.isBackgroundTransparent !== this.isBackgroundTransparent ||
      oldItemObject.qrBackgroundColor !== this.qrBackgroundColor
    );
  };

  public async isPDFSafe(): Promise<boolean> {
    return true;
  }

  public hasClickableLink(): boolean {
    return false;
  }

  public toObject(): QRCodeItemObject {
    return {
      ...super.toObject(),
      message: this.message,
      qrForegroundColor: this.qrForegroundColor,
      qrBackgroundColor: this.qrBackgroundColor,
      isBackgroundTransparent: this.isBackgroundTransparent,
    };
  }

  public updateForegroundColor = (color: RGB, undoable = true): void => {
    void this.updateFromObject(
      {
        qrForegroundColor: color,
      },
      {
        undoable,
      }
    );
  };

  public updateMessageContent = (updatedValue: string, undoable = true): void => {
    void this.updateFromObject(
      {
        message: updatedValue,
      },
      {
        undoable,
      }
    );
  };

  public toggleBackgroundStateForQRItem = (): void => {
    void this.updateFromObject({
      isBackgroundTransparent: !this.isBackgroundTransparent,
    });
  };

  public updateQRBackgroundColor = (color: RGB, undoable = true): void => {
    void this.updateFromObject(
      {
        qrBackgroundColor: color,
      },
      {
        undoable,
      }
    );
  };

  protected async getFabricObjectForItem(): Promise<Group | FabricObject> {
    const qrCodeString = await QRCode.toString(this.message, {
      type: 'svg',
      width: 256,
      errorCorrectionLevel: 'medium',
      margin: 0,
      color: {
        light: this.isBackgroundTransparent ? '#FFFFFF01' : rgbToHexString(this.qrBackgroundColor),
        dark: rgbToHexString(this.qrForegroundColor),
      },
    });
    const svgParsed = await loadSVGFromString(qrCodeString);
    if (!svgParsed.objects) {
      throw new Error(`Failed to load svg for string ${this.message}`);
    }

    const objectsWithoutNulls = svgParsed.objects.filter((item) => {
      return item !== null;
    }) as Array<FabricObject>;
    const obj = util.groupSVGElements(objectsWithoutNulls, svgParsed.options);
    obj.set(this.getCommonOptions());
    return obj;
  }

  protected onItemDoubleTapped(): void {
    openPosterEditorQRContentModal();
  }
}

export const addQrCodeToPage = (): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }
  void currentPage.items.addItems.addQRItem({
    type: ElementDataType.QR_CODE,
  });
};
