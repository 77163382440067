import {ITEM_CONTROL_DIMENSIONS} from '@PosterWhiteboard/poster/poster-item-controls';
import {isMobile} from 'react-device-detect';
import type {FabricObject, CanvasEvents} from '@postermywall/fabricjs-2';
import {Canvas} from '@postermywall/fabricjs-2';
import type {Page} from './page.class';

export class ItemBordersOnHovering {
  public page: Page;
  private mouseOverObject: FabricObject | null = null;

  constructor(page: Page) {
    this.page = page;
    // Sometimes on mobile mouse:over fires on touch but not mouse:out, leaving hover borders there forever
    if (isMobile || !(this.page.fabricCanvas instanceof Canvas)) {
      return;
    }
    this.page.fabricCanvas.on('object:removed', this.onObjectRemoved.bind(this));
    this.page.fabricCanvas.on('mouse:over', this.onObjectMouseOver.bind(this));
    this.page.fabricCanvas.on('mouse:out', this.onObjectMouseOut.bind(this));
    this.page.fabricCanvas.on('before:render', this.clearContext.bind(this));
    this.page.fabricCanvas.on('after:render', this.afterCanvasRender.bind(this));
  }

  /**
   * Marks the object mouse is over and renders canvas to draw the borders around it
   * @param {object} e
   * @private
   */
  private onObjectMouseOver(e: CanvasEvents['mouse:over']): void {
    if (e.target && e.target.type !== 'activeSelection' && e.target.selectable) {
      this.mouseOverObject = e.target;
      this.page.fabricCanvas.renderAll();
    }
  }

  /**
   * Clears the mouseOverObject if it was the object removed
   * @private
   */
  private onObjectRemoved(e: CanvasEvents['object:removed']): void {
    if (e.target && e.target === this.mouseOverObject) {
      this.mouseOverObject = null;
    }
  }

  /**
   * Marks the object mouse is over as null and renders canvas to clear any borders drawn
   * @private
   */
  private onObjectMouseOut(): void {
    this.mouseOverObject = null;
    this.page.fabricCanvas.renderAll();
  }

  /**
   * Clears the canvas context
   * @private
   */
  private clearContext(): void {
    if (this.page.fabricCanvas instanceof Canvas && this.page.fabricCanvas.contextTop) {
      this.page.fabricCanvas.contextTopDirty = true;
    }
  }

  /**
   * Handler for drawing border around the marked object that the mouse is over. Ignores drawing border if the
   * object is active in which case borders with controls are already visible.
   * @private
   */
  private afterCanvasRender(): void {
    if (this.mouseOverObject && !this.isMouseOverObjectActive()) {
      this.drawBorder();
    }
  }

  /**
   * Draws the border around object with mouse over it
   * @private
   */
  private drawBorder(): void {
    if (!this.mouseOverObject) {
      return;
    }

    const item = this.page.items.getItemForFabricObject(this.mouseOverObject);
    if (!item) {
      return;
    }

    const ctx = this.page.fabricCanvas.contextContainer;
    ctx.strokeStyle = item.getBorderColor();
    ctx.lineWidth = ITEM_CONTROL_DIMENSIONS.BORDER_THICKNESS;

    const coords = this.mouseOverObject.calcOCoords();

    ctx.beginPath();
    ctx.moveTo(coords.tl.x, coords.tl.y);
    ctx.lineTo(coords.tr.x, coords.tr.y);
    ctx.lineTo(coords.br.x, coords.br.y);
    ctx.lineTo(coords.bl.x, coords.bl.y);
    ctx.lineTo(coords.tl.x, coords.tl.y);
    ctx.stroke();
    ctx.restore();
  }

  /**
   * Returns whether the object with mouse over it is active or not
   * @return {boolean}
   * @private
   */
  private isMouseOverObjectActive(): boolean {
    const activeObjects = this.page.activeSelection.getActiveObjects();

    for (let i = 0; i < activeObjects.length; i++) {
      if (activeObjects[i] === this.mouseOverObject) {
        return true;
      }
    }

    return false;
  }
}
