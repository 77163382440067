import type {ImageItemObject} from '@PosterWhiteboard/items/image-item/image-item.class';
import {ImageItem} from '@PosterWhiteboard/items/image-item/image-item.class';
import {DEFAULT_SLIDE_DURATION} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.types';
import type {SlideshowItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.class';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type {MediaSlideObject} from '@PosterWhiteboard/items/slideshow-item/media-slide.class';
import {MediaSlide} from '@PosterWhiteboard/items/slideshow-item/media-slide.class';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {RGB} from '@Utils/color.util';
import {rgbToHex} from '@Utils/color.util';
import type {MaskingObject} from '@PosterWhiteboard/classes/masking/masking.class';
import type {ItemFabricObject} from '@PosterWhiteboard/items/item/item.class';
import type {DeepPartial} from '@/global';

export interface ImageSlideItemObject extends ImageItemObject {
  slideDuration: number;
  mediaSlide: MediaSlideObject;
}

export class ImageSlideItem extends ImageItem {
  public gitype = ITEM_TYPE.IMAGESLIDE;
  public slideDuration = DEFAULT_SLIDE_DURATION;
  public slideshow: SlideshowItem;
  public mediaSlide: MediaSlide;

  public constructor(page: Page, slideshow: SlideshowItem) {
    super(page);
    this.slideshow = slideshow;
    this.mediaSlide = new MediaSlide(this);
  }

  public toObject(): ImageSlideItemObject {
    return {
      ...super.toObject(),
      slideDuration: this.slideDuration,
      mediaSlide: this.mediaSlide.toObject(),
    };
  }

  public copyVals(obj: DeepPartial<ImageSlideItemObject>): void {
    const {mediaSlide, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.mediaSlide.copyVals(mediaSlide);
  }

  public getParentFabricObject(): ItemFabricObject {
    return this.slideshow.fabricObject;
  }

  protected getCommonOptions(): Record<string, any> {
    const {left, top, opacity, ...filteredProps} = super.getCommonOptions();
    return filteredProps;
  }

  /**
   * Getter for slideDuration property
   */
  public getSlideDuration(): number {
    return this.slideDuration;
  }

  /**
   * Setter for slideDuration property
   */
  public setSlideDuration(val: number): void {
    this.slideDuration = val;
  }

  protected async reInitFabricObject(): Promise<void> {
    const oldFabricObject = this.fabricObject;
    const slideshowFabricObject = this.slideshow.fabricObject;

    await this.initFabricObject();
    this.fabricObject.set({left: oldFabricObject.left, top: oldFabricObject.top});
    slideshowFabricObject.remove(oldFabricObject);
    this.slideshow.addSlideToGroupWithOriginalScale(this.fabricObject);
  }

  public getFill(): string {
    return `#${rgbToHex(this.slideshow.transition.color)}`;
  }

  public isChildItem(): boolean {
    return true;
  }

  public getColors(): Array<RGB> {
    return super.getColors();
  }

  public getAppliedSidebarProps(): DeepPartial<ImageSlideItemObject> {
    return {
      aura: this.aura.toObject(),
      border: this.border.toObject(),
      effects: this.effects.toObject(),
      mediaSlide: this.mediaSlide.toObject(),
    };
  }

  public async enableRemoveBackgroundFlag(): Promise<void> {
    await this.slideshow.updateSlideFromObject(this.uid, {
      removeBackground: {
        isBackgroundRemoved: true,
      },
    });
  }

  public async disableRemoveBackgroundFlag(): Promise<void> {
    await this.slideshow.updateSlideFromObject(this.uid, {
      removeBackground: {
        isBackgroundRemoved: false,
      },
    });
  }

  public async updateMasking(maskingObject?: MaskingObject): Promise<void> {
    await this.slideshow.updateSlideFromObject(this.uid, {
      masking: {
        maskingItem: maskingObject,
      },
    });
  }
}
