import {ITEM_CONTROL_DIMENSIONS} from '@PosterWhiteboard/poster/poster-item-controls';
import type {ItemType} from '@PosterWhiteboard/items/item/item.types';
import type * as Fabric from '@postermywall/fabricjs-2';

export const getScaledManualHorizontalPadding = (scaleX: number | undefined): number => {
  return 2 * getScaledManualLeftPadding(scaleX);
};

export const getScaledManualVerticalPadding = (scaleY: number | undefined): number => {
  return 2 * getScaledManualTopPadding(scaleY);
};

export const getScaledManualLeftPadding = (scaleX: number | undefined): number => {
  return scaleX ? ITEM_CONTROL_DIMENSIONS.PMW_CONTROL_PADDING / scaleX : 1;
};

export const getScaledManualTopPadding = (scaleY: number | undefined): number => {
  return scaleY ? ITEM_CONTROL_DIMENSIONS.PMW_CONTROL_PADDING / scaleY : 1;
};

export const getCumulativeVCScaleY = (vc: Fabric.Group | Fabric.Object | undefined): number => {
  return vc ? vc.scaleY * getCumulativeVCScaleY(vc.group) : 1;
};

export const getCumulativeVCScaleX = (vc: Fabric.Group | Fabric.Object | undefined): number => {
  return vc ? vc.scaleX * getCumulativeVCScaleX(vc.group) : 1;
};

export const getMaxDurationItem = (items: Array<ItemType>): ItemType | undefined => {
  let maxDurationItem;
  for (const item of items) {
    if (item && (!maxDurationItem || maxDurationItem.getPlayDuration() < item.getPlayDuration())) {
      maxDurationItem = item;
    }
  }
  return maxDurationItem;
};
