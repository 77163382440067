import type {Poster} from '@PosterWhiteboard/poster/poster.class';
import type {
  UpdatePosterDataProps} from '@Components/poster-editor/poster-editor-reducer';
import {
  setSelectedAudioItemUID,
  updateActiveItems,
  updateAudioItemIsPlaying,
  updateCurrentTime,
  updateHasRedo,
  updateHasUndo,
  updateIsPlaying,
  updatePosterData
} from '@Components/poster-editor/poster-editor-reducer';
import {PosterEvent} from '@PosterWhiteboard/poster/poster.types';

export class PosterRedux {
  private readonly poster: Poster;

  constructor(poster: Poster) {
    this.poster = poster;
    this.poster.on(PosterEvent.PAGE_TIME_UPDATED, (time: number) => {
      window.PMW.redux.store.dispatch(updateCurrentTime(time));
    });
  }

  public updateReduxData = (): void => {
    window.PMW.redux.store.dispatch(updatePosterData(this.getPosterDataForRedux()));
  };

  public updateHasUndoInRedux = (): void => {
    window.PMW.redux.store.dispatch(updateHasUndo(this.poster.history.hasUndo()));
  };

  public updateHasRedoInRedux = (): void => {
    window.PMW.redux.store.dispatch(updateHasRedo(this.poster.history.hasRedo()));
  };

  public updateIsPlayingInRedux = (): void => {
    window.PMW.redux.store.dispatch(updateIsPlaying(this.poster.isPlaying()));
  };

  public updateAudioItemIsPlayingInRedux = (audioItemUID: string, isPlaying: boolean): void => {
    window.PMW.redux.store.dispatch(
      updateAudioItemIsPlaying({
        audioItemUID,
        isPlaying,
      })
    );
  };

  public updateSelectedAudioItemUID = (): void => {
    window.PMW.redux.store.dispatch(setSelectedAudioItemUID(this.poster.audioClips.selectedAudioItemUID));
  };

  public updateSelectedItemsInRedux = (): void => {
    const selectedItemIds = this.poster.getSelectedItems().map((selectedItem) => {
      return selectedItem.uid;
    });

    window.PMW.redux.store.dispatch(updateActiveItems(selectedItemIds));
  };

  public updateUndoRedoStates(): void {
    this.updateHasUndoInRedux();
    this.updateHasRedoInRedux();
  }

  private getPosterDataForRedux = (): UpdatePosterDataProps => {
    return {
      isVideo: this.poster.isVideo(),
      posterObject: this.poster.toObject(),
      currentPageId: this.poster.getCurrentPage().hashedID,
      isPosterVideo: this.poster.isVideo(),
      hasUnsavedChanges: this.poster.savePoster.hasUnsavedChanges(),
    };
  };
}
