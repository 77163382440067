import type {ReactElement} from 'react';
import React, {useEffect} from 'react';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from '@Components/text';
import type {UserMediaType, UserMediaVOResponse} from '@Libraries/user-media-library';
import {attachMediaDrop, userMediaVOToElementData} from '@Libraries/user-media-library';
import {isArray} from 'lodash';
import {onAddItems} from '@Components/poster-editor/library/poster-editor-open-modals';
import {noop} from '@Utils/general.util';
import styles from './drag-drop-overlay.module.scss';

function DragDropOverlay(): ReactElement {
  const onMediaUploaded = (type: UserMediaType, data: Array<UserMediaVOResponse> | UserMediaVOResponse): void => {
    const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
    if (!currentPage) {
      return;
    }
    if (!isArray(data)) {
      const itemData = userMediaVOToElementData(type, data);
      onAddItems([itemData]);
    }
  };

  useEffect(() => {
    attachMediaDrop('.whiteboard-container', onMediaUploaded, noop);
  }, []);

  return (
    <div className={`dragdropoverlay _full-width _full-height ${styles.container}`}>
      <div className={`_full-width _full-height flex-center flex-v-row spacing-p-13 ${styles.content}`}>
        <Icon icon="icon-upload-1" size={IconSize.SIZE_ICON_32} type={IconType.FLAT} shape={IconShape.CIRCLE} />
        <Text className="spacing-m-t-3" val={window.i18next.t('pmwjs_drag_drop_message')} size={TextSize.SMALL} />
      </div>
    </div>
  );
}

export default DragDropOverlay;
