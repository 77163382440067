import {BackgroundTypeName} from '@PosterWhiteboard/page/background/background.class';
import type {ImageBackgroundObject} from '@PosterWhiteboard/page/background/image-background.class';
import {createImageBackgroundFromObject, ImageBackground} from '@PosterWhiteboard/page/background/image-background.class';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type {ColorBackgroundObject} from '@PosterWhiteboard/page/background/color-background.class';
import {ColorBackground, createColorBackgroundFromObject} from '@PosterWhiteboard/page/background/color-background.class';
import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import {FillTypes} from '@PosterWhiteboard/classes/fill.class';
import {hexToRgb} from '@Utils/color.util';
import {TransparencyStates} from '@PosterWhiteboard/page/background/image-background-item.class';
import {DEFAULT_BACKGROUND_COLOR} from '@PosterWhiteboard/page/page.types';
import type {DeepPartial} from '@/global';

export type BackgroundType = ColorBackground | ImageBackground;
export type BackgroundObject = ColorBackgroundObject | ImageBackgroundObject;

export interface PageBackgroundObject {
  details: BackgroundObject;
}

export class PageBackground extends CommonMethods {
  public page: Page;
  public details!: BackgroundType;

  constructor(page: Page) {
    super();
    this.page = page;
    this.details = new ColorBackground(page);
  }

  public toObject(): PageBackgroundObject {
    return {
      details: this.details.toObject(),
    };
  }

  public async updateFromObject(
    pageBackgroundObject: DeepPartial<PageBackgroundObject>,
    {updateRedux = true, undoable = true, doInvalidate = true}: UpdateFromObjectOpts = {}
  ): Promise<void> {
    if (pageBackgroundObject.details) {
      if (pageBackgroundObject.details.type !== undefined && this.details.type !== pageBackgroundObject.details.type) {
        await this.createBackgroundFromObject(pageBackgroundObject as PageBackgroundObject);
        this.details.onBackgroundCreated();
      } else if (this.details instanceof ColorBackground) {
        await this.details.updateFromObject(pageBackgroundObject.details as ColorBackgroundObject, {
          updateRedux: false,
          undoable: false,
          doInvalidate,
        });
      } else if (this.details instanceof ImageBackground) {
        await this.details.updateFromObject(pageBackgroundObject.details as ImageBackgroundObject, {
          updateRedux: false,
          undoable: false,
          doInvalidate,
        });
      } else {
        throw new Error('Unhandled background type');
      }

      if (doInvalidate) {
        this.invalidate();
      }

      if (undoable) {
        this.page.poster.history.addPosterHistory();
      }
      if (updateRedux) {
        this.page.poster.redux.updateReduxData();
      }
    }
  }

  public isTransparent(): boolean {
    return (
      (this.details instanceof ColorBackground && !this.details.fill.hasFill()) ||
      (this.details instanceof ImageBackground && this.details.imageBackgroundItem.isBackgroundImageTransparencyOn())
    );
  }

  public isPremium(): boolean {
    return this.details instanceof ImageBackground && this.details.imageBackgroundItem.isPremium();
  }

  private async createBackgroundFromObject(backgroundObject: PageBackgroundObject): Promise<void> {
    let background;
    switch (backgroundObject.details.type) {
      case BackgroundTypeName.COLOR:
        background = await createColorBackgroundFromObject(this.page, backgroundObject.details);
        break;

      case BackgroundTypeName.IMAGE:
        background = await createImageBackgroundFromObject(this.page, backgroundObject.details);
        break;

      default:
        throw new Error(`Couldn't load background. Unknown type`);
    }
    this.details = background;
  }

  public invalidate(): void {
    if (this.isTransparent()) {
      this.showTransparentBackgroundForTransparentBackground();
    } else {
      if (this.details instanceof ImageBackground) {
        this.page.fabricCanvas.backgroundColor = '#ffffff';
      }
      this.hideTransparentBackgroundPattern();
    }
    this.page.fabricCanvas.renderAll();
  }

  public showWhiteBackgroundForTransparentBackground(): void {
    if (this.isTransparent()) {
      this.page.fabricCanvas.backgroundColor = '#ffffff';
      this.hideTransparentBackgroundPattern();
    }
  }

  public showTransparentBackgroundForTransparentBackground(): void {
    if (this.isTransparent()) {
      this.page.fabricCanvas.backgroundColor = '#00000000';
      this.showTransparentBackgroundPattern();
    }
  }

  public hasImageBackground(): boolean {
    return this.details instanceof ImageBackground;
  }

  public isTransparentBackground(): boolean {
    return (
      (this.details.type === BackgroundTypeName.COLOR && !this.details.fill.hasFill()) ||
      (this.details.isImage() && this.details.imageBackgroundItem.isBackgroundImageTransparencyOn())
    );
  }

  public updateBackgroundToBeNonTransparent(): void {
    if (this.details.isImage() && this.details.imageBackgroundItem.isBackgroundImageTransparencyOn()) {
      this.switchOffImageBackgroundTransparency();
    } else {
      this.updateBackgroundToDefaultState();
    }
  }

  public switchOffImageBackgroundTransparency(): void {
    void this.updateFromObject(
      {
        details: {
          imageBackgroundItemObject: {
            transparency: TransparencyStates.OFF,
          },
        },
      },
      {undoable: false}
    );
  }

  public updateBackgroundToDefaultState(): void {
    void this.updateFromObject(
      {
        details: {
          type: BackgroundTypeName.COLOR,
          fill: {
            fillType: FillTypes.SOLID,
            fillColor: [hexToRgb(DEFAULT_BACKGROUND_COLOR)],
          },
        },
      },
      {undoable: false}
    );
  }

  private showTransparentBackgroundPattern(): void {
    this.page.fabricCanvas.lowerCanvasEl.classList.add('-transparent-background');
  }

  private hideTransparentBackgroundPattern(): void {
    this.page.fabricCanvas.lowerCanvasEl.classList.remove('-transparent-background');
  }
}
